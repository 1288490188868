










import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class SaveChannelConfig extends Vue {
  @Prop() onClick!: () => void

  @Prop({ type: Boolean, required: false, default: false }) disabled!:boolean

  saveChannelConfig(): void {
    if (typeof this.onClick === 'function') {
      this.onClick()
    } else {
      this.$store.dispatch('save_active_channel_config', this.$route)
    }
  }
}




















































































import { InputSetups } from "../../mixins/input-setups"

import { UseFields } from "piramis-base-components/src/components/Pi"

import { Component, Mixins, VModel } from "vue-property-decorator"

@Component
export default class SetPeriod extends Mixins<UseFields, InputSetups>(UseFields, InputSetups){
  @VModel() periodSettings!: { from: { date: string, time: string}, to: { date: string, time: string }, onlyJoinFirstTime: boolean }
}





















































































import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'
import {
  ClearUsersRequest,
  ClearUsersRequestActions as ReqActions,
  RequestStatus
} from "@/includes/types/Channel.types"
import { ChannelService } from "@/includes/services/ChannelService";

import { UseFields } from "piramis-base-components/src/components/Pi/index";
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { mapGetters } from 'vuex'
import { Component, Mixins, Prop, Watch } from "vue-property-decorator"

@Component({
  data() {
    return {
      RequestStatus
    }
  },
  computed: {
    ...mapGetters([ 'getBoardById' ])
  }
})
export default class RemoveUsersRequestStat extends Mixins<UseFields>(UseFields) {
  @Prop() request!: ClearUsersRequest

  getBoardById!:GetBoardByIdType

  process = false

  failPercent = 0

  successPercent = 0

  @Watch('request.status')
  statusWatcher(status: RequestStatus): void {
    if (status === RequestStatus.Process) {
      this.process = true
      this.processGetStat()
    } else {
      this.process = false
    }
  }

  getSuccessPercent(): void {
    const percent = this.request.users / 100
    this.successPercent = Math.round(this.request.success / percent)
  }

  getFailPercent(): void {
    const percent = this.request.users / 100
    this.failPercent = Math.round(this.request.fail / percent)
  }

  showAlert(): void {
    this.$emit('notifyBeforeRemoving', this.request.key)
  }

  processGetStat(): void {
    if (this.process) {
      setTimeout(() => {
        ChannelService.clearUsersRequest('tg', { action: ReqActions.Status, key: this.request.key })
          .then((res) => {
            this.$emit('setRequestStats', res)
          })
          .finally(() => {
            this.processGetStat()
            this.getFailPercent()
            this.getSuccessPercent()
          })
      }, 2000)

    }
  }

  removeRequestSettings(): void {
    this.$emit('cancelRequest', this.request.key)
  }

  goRemove(): void {
    ChannelService.clearUsersRequest('tg', { action: ReqActions.Confirm, key: this.request.key, users: this.request.users })
      .then((res) => {
        if (!('items' in res)) {
          const status = res.status
          this.$emit('setStatus', { key: this.request.key, status })
          return status
        }
      })
      .then((status) => {
        if (status === RequestStatus.Started) {
          ChannelService.clearUsersRequest('tg', { action: ReqActions.Status, key: this.request.key })
            .then((res) => {
              if (!('items' in res)) {
                const newStatus = res.status
                this.$emit('setStatus', { key: this.request.key, newStatus })
              }
            })
        }
      })
  }

  confirmedRequest(): void {
    ChannelService.clearUsersRequest('tg', {
      action: ReqActions.Cancel,
      key: this.request.key,
      board_key: this.getBoardById(this.$route.params[ EntityTypes.BOT_ID ])!.board
    })
      .then((res) => {
        if (!('items' in res)) {
          this.$emit('setStatus', { key: this.request.key, status: RequestStatus.Stop })
        }
      })
  }

  mounted(): void {
    this.getSuccessPercent()
    this.getFailPercent()

    if (this.request.status === RequestStatus.Process) {
      this.process = true
      this.processGetStat()
    }
  }

  destroyed(): void {
    this.process = false
  }
}

















import Vue from 'vue'
import { Component, Prop } from "vue-property-decorator";
import { ClearUsersRequest, RequestStatus } from "@/includes/types/Channel.types"

@Component({
  data() {
    return {
      RequestStatus
    }
  }
})
export default class ClearUsersRequestView extends Vue {
  @Prop() request!: ClearUsersRequest
}
